@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Rubik';
  src: url('/public/fonts/Rubik-VariableFont_wght.ttf');
  /* Add more font formats if needed */
}

html, body, span, div, h1, h2, h3, h4, h5, h6, text, p, style, text {
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  /* font-family: 'ABeeZee-Bold'; */
}


.arabic_amount-input {
  font-size: 54,;
  text-align: center;
  font-family: "Rubik";
  border-top-width: '0px';
  border-left-width: '0px';
  border-right-width: '0px';
  border-bottom-width: '2px';
  border-color:  '#D9D9D9';
  color: "#A87F2E";
  font-weight: "500";
}

::placeholder {
  color: #D9D9D9;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
